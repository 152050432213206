<template>
  <div v-if="dialog">
    <v-dialog :value="dialog" persistent transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar>
          <v-btn icon @click="$emit('onCloseDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Player white list
          </v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn v-if="loading" disabled text @click="validate">
              {{$t("button.create")}}
            </v-btn>
            <v-btn v-else text @click="validate">
              {{$t("button.create")}}
            </v-btn>
          </v-toolbar-items>
        
        </v-toolbar>
        <Loading :visible="loading" />
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-autocomplete
                  v-model="whiteListData.user_id"
                  :items="items"
                  :rules="rules.user_name"
                  cache-items
                  :loading="isLoading"
                  :search-input.sync="search"
                  color="white"
                  hide-no-data
                  item-text="name"
                  item-value="_id"
                  label="Select player"
                  placeholder="Start typing to Search"
                ></v-autocomplete>
              </v-row>
              <v-row>
                <v-select
                  v-model="whiteListData.channel_type"
                  :items="cockChannelType"
                  item-text="name"
                  item-value="_id"
                  :label="$t('input.channel_type')"
                />
              </v-row>
              <v-row>
                <v-text-field
                  v-model="whiteListData.amount_min"
                  :label="$t('input.amount_min')"
                  :rules="rules.amount_min"
                  type="text"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                   v-model="whiteListData.amount_max"
                  :label="$t('input.amount_max')"
                  :rules="rules.amount_max"
                  type="text"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                 v-model="whiteListData.betting_frequency"
                  :label="$t('input.betting_frequency_1')"
                  :rules="rules.betting_frequency"
                  type="text"
                ></v-text-field>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<style>
img {
  width: 30%;
  margin: auto;
  display: block;
  margin-bottom: 10px;
}
button {
}
</style>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    darkmode: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    rules: {
      type: Object,
      default: function () {
        return {
          user_name: [(v) => !!v || "Username is required"],
          amount_min: [(v) => !!v || "Amount Min is required"],
          amount_max: [(v) => !!v || "Amount Max is required"],
          betting_frequency: [(v) => !!v || "Betting Frequency is required"],
        };
      },
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:isValidate", newValue);
      },
    },

    items () {
        return this.entries;
      },
  },
  data: () => {
    return {
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      selectUser: null,
      search: null,
      whiteListData: {
        user_id: null,
        amount_min: 0,
        amount_max: 0,
        betting_frequency: 3,
        channel_type: 1,
      },
      cockChannelType: [
        { _id: 1, name: "CO1" },
        { _id: 2, name: "CO2" },
        { _id: 3, name: "CO3" },
        { _id: 4, name: "CO4" },
      ]
    };
  },
  watch: {
    async search (val) {
        this.isLoading = true
        // Lazily load input items
        let data = {
          search: val
        }
        await this.$request
        .post({
          url: "cock-whitelist/getListPlayer",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            console.log("res.data.data.object", res.data.data);
            this.entries = res.data.data;
            this.isLoading = false;
          } 
        })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      }
  },
  methods: {
    validate() {
     
      if (this.$refs.form.validate()) {
        this.$emit("submit", this.whiteListData);
      }
    },
  },
  
};
</script>


